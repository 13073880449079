<template>
  <div class="c-text-element">
    <div class="o-heading">
      <VHeading v-if="content.title"
        header="h3"
        class="c-text-element__title"
        :text="content.title" />
      <VHeading v-if="content.subtitle"
        header="h5"
        class="c-text-element__subtitle"
        :text="content.subtitle" />
    </div>
    <div v-md-html="content.body"
      class="c-text-element__text" />
  </div>
</template>

<script>
import VHeading from '@forlagshuset/v-heading'

export default {
  components: {
    VHeading
  },

  props: {
    content: Object
  }
}
</script>

<style lang="scss">
.c-text-element__title {
  clear: right;
}
</style>