<template>
  <div
    v-if="isOpen"
    class="c-modal"
    v-shortkey="['esc']"
    @shortkey="$store.dispatch('modal/clearModal')"
    v-click-outside="onClickOutside"
  >
    <focus-trap :active="isOpen">
      <div class="c-modal__content" tabindex="-1">
        <button
          class="c-modal__close"
          @click="$store.dispatch('modal/clearModal')"
        >
          <Close />
        </button>
        <FHButton class="c-concept c-modal__content-btn u-px u-py u-mb">
          {{ content.name }}
        </FHButton>
        <div
          class="c-modal__content-description"
          v-md-html="content.description"
        />
        <div class="c-modal__content-le">
          <component
            v-for="item in content.content"
            :key="item._id"
            :is="componentNameNormalize(item.__component)"
            :content="item"
            class="c-modal__content-component"
          />
        </div>
      </div>
    </focus-trap>
  </div>
</template>

<script>
import { computed } from '@vue/composition-api'
import { componentNameNormalize } from '@/utils'
import ComponentLearningElementTextBox from '@/components/TextBoxElement'
import ComponentLearningElementTextElement from '@/components/TextElement'
import ComponentLearningElementConceptElement from '@/components/ConceptElement'
import ComponentLearningElementAudioElement from '@/components/AudioElement'
import ComponentLearningElementImageElement from '@/components/ImageElement'
import ComponentLearningElementVideoElement from '@/components/VideoElement'
import ComponentLearningElementSectionTagElement from '@/components/SectionTagElement'
import ComponentLearningElementSectionEndTagElement from '@/components/SectionEndTagElement'
import Close from '@/assets/svg/Close'
import FHButton from '@forlagshuset/v-button'

export default {
  name: 'Modal',

  data() {
    return {
      vcoConfig: {
        events: ['dblclick', 'click'],
        isActive: true,
        detectIFrame: true,
        capture: false,
      },
    }
  },
  methods: {
    onClickOutside(event) {
      if (!event.target.classList.contains('medium-zoom-image--opened')) {
        this.$store.dispatch('modal/clearModal')
      }
    },
  },
  components: {
    FHButton,
    Close,
    ComponentLearningElementTextBox,
    ComponentLearningElementTextElement,
    ComponentLearningElementConceptElement,
    ComponentLearningElementAudioElement,
    ComponentLearningElementImageElement,
    ComponentLearningElementVideoElement,
    ComponentLearningElementSectionTagElement,
    ComponentLearningElementSectionEndTagElement,
  },
  watch: {
    isOpen: function() {
      if (this.isOpen) {
        document.body.style.overflow = 'hidden'
        return
      }
      document.body.style.overflow = 'auto'
    },
  },
  setup(props, ctx) {
    const isOpen = computed(() => ctx.root.$store.state.modal.isOpen)
    const content = computed(() => ctx.root.$store.state.modal.content)

    return {
      isOpen,
      content,
      componentNameNormalize,
    }
  },
}
</script>

<style lang="scss">
.c-modal {
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  margin: 0 auto;
  left: 0;
  right: 0;
  max-height: 95vh;
  max-width: $medium;
  background: $color-white;
  z-index: 900;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 1.1111111111111112rem;
  overflow: auto;

  &__content {
    padding: 4rem 2rem;
    font-family: CircularPro, sans-serif;

    &-description {
      font-size: 1.2rem;
      margin: 2.5rem 0 0;
    }
  }
  &__close {
    @include btnReset();

    position: absolute;
    right: 1rem;
    top: 1rem;
  }
}

div::-webkit-scrollbar-thumb {
  background-color: #b8bcc5;
  border-radius: 0.5rem;

  &:hover {
    background-color: darken(#b8bcc5, 5%);
  }
}
div::-webkit-scrollbar {
  width: 0.5rem;
}
</style>
