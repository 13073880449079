<template>
  <div v-if="content" class="c-video-element">
    <VHeading header="h2"
      :text="content.video_type.name"
      class="c-video-element__title o-heading" />

    <FHVideoPlayer
      ref="player"
      :video-url="content.video_type.file.url"
      :video-type="content.video_type.file.mime"
      :captions="captions"
      :video-attributes="videoAttributes"
      :options="options"
      :chapters="content.video_type.mediaChapter"
      @timeupdate="onTimeUpdate"
      @texttracksloaded="onTextTrackLoad"
    />

    <div class="c-video-player__chapters">
      <FHVideoChapter
        v-for="chapter in content.video_type.mediaChapter"
        :key="chapter.title"
        :title="chapter.title"
        :start="chapter.start"
        :end="chapter.end"
        :currentTime="playerCurrentTime"
        @click.native="updateCurrentTime(chapter.start)"
      />
    </div>
  </div>
</template>

<script>
import VHeading from '@forlagshuset/v-heading'
import FHVideoPlayer from '@forlagshuset/v-video-player-vjs'
import FHVideoChapter from '@forlagshuset/v-video-chapter'
import i18n from  '@/i18n'
export default {
  components: {
    VHeading,
    FHVideoPlayer,
    FHVideoChapter
  },

  methods: {
    onTextTrackLoad(tracks) {
      this.textTracks = tracks
      this.currentTextTrack = this.textTracks.filter(track => track.language === 'nb')[0] // Look for current language in app
    },

    onTimeUpdate(time) {
      this.playerCurrentTime = time
    },

    updateCurrentTime(time) {
      this.$refs.player.player.currentTime(time)
      this.$refs.player.player.play()
    },
  },

  props: {
    content: Object
  },

  setup (props) {
    const prepareCaptions = () => {
      if (!props.content.video_type.closedCaption) return []
      let captions = []

      captions.push({
        label: props.content.video_type.closedCaption.file.alternativeText,
        src: props.content.video_type.closedCaption.file.url,
        srclang: 'nb' // no info about language from strapi - reported
      })

      return captions

    }
    const captions = prepareCaptions()
    const videoAttributes = {
      poster: props.content.video_type.previewUrl,
      crossorigin: '',
      playsinline: '',
      controls: ''
    }

    const options = {
      playbackRates: [0.5, 1, 1.5, 2],
      language: i18n.locale
    }

    return {
      captions,
      videoAttributes,
      options
    }
  }
}
</script>

<style lang="scss">
.c-video-element__title {
  display: none;
}
</style>