<template>
  <div :class="[ 
    'c-text-box-element', 
    content.properties[0] ? `c-text-box-element--${content.properties[0].value.toLowerCase()}` : '',
    content.properties[1] ? `c-text-box-element--${content.properties[1].value.toLowerCase()}` : '' 
  ]">
    <div v-md-html="content.content"
      class="c-article__section-text" />
  </div>
</template>

<script>
export default {
  props: {
    content: Object
  }
}
</script>

<style lang="scss">
.c-text-box-element {
  position: relative;
  clear: right;

  a {
    [class*=c-text-box-element--] & {
      color: $color-white;
    }
    font-weight: 700;
  }

  &--factbox {
    border-radius: 1.2rem;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.5);

    margin: 2rem;
    padding: 2rem;
    color: #fff;

  }
  &--speechbubble {
    width: 80%;
    p:first-of-type {
      position: relative;
      border-radius: 1.2rem;
      box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.5);

      width: max-content;
      max-width: calc(100% - 8rem);
      margin-left: 2rem;
      margin-bottom: 2rem;
      padding: 2rem;
      color: #fff;
      &:before {
        content: '';
        width: 1rem;
        height: 1rem;
        transform: rotate(45deg);
        left: 1.5rem;
        bottom: -.5rem;
        position: absolute;
      }
    }
    &:nth-of-type(2n) {
      p:first-of-type {
        margin-left: calc(25% - 6rem);
        &:before {
          left: auto;
          right: 1.5rem
        }
      }
    }
    p:last-of-type {
      margin-left: 0rem;
      margin-bottom: 2rem;
    }
    &:nth-of-type(2n) {
      p:last-of-type {
        margin-right: 2rem;
        float: right;
        text-align: right;
        max-width: calc(100% - 8rem)
      }
    }
  }
  &--discuss {
    border-radius: 1.2rem;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.5);

    width: calc(80% - 4rem);
    margin-left: 2rem;
    margin-bottom: 2rem;
    padding: 2rem;
    color: #fff;
  }
  &--against {
    color: $color-black;
    margin-left: 20%;
  }
  &--backgroundcolor {
    a {
      color: $color-black;
    }
  }
}
</style>