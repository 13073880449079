<template>
  <figure
    class="c-image-element"
    :class="{ 'c-image-element--full': isLandscape }"
  >
    <picture
      v-if="content.image_type"
      class="c-image-element__picture"
      :class="`c-image-element__picture--${extension}`"
    >
      <img
        ref="image"
        :src="content.image_type.file.url"
        :alt="content.image_type.altText"
        :title="
          content.image_type.license ? content.image_type.license.credit : ''
        "
        class="c-image-element__img"
      />
    </picture>
    <div
      class="o-wrapper"
      v-if="content.image_type && content.image_type.description"
    >
      <figcaption class="c-image-element__figcaption">
        {{ content.image_type.description }}
      </figcaption>
    </div>
  </figure>
</template>

<script>
import mediumZoom from 'medium-zoom'
import { isFullWidth } from '@/utils'

export default {
  computed: {
    extension() {
      return this.content.image_type.file.mime.split('/')[1].split('+')[0]
    },
  },

  data() {
    return {
      isLandscape: false,
    }
  },

  props: {
    content: Object,
  },

  mounted() {
    mediumZoom(this.$refs.image)

    this.isLandscape = isFullWidth(
      this.content.image_type.file.width,
      this.content.image_type.file.height,
    )
  },
}
</script>

<style lang="scss">
.medium-zoom-overlay {
  z-index: 999;
}
.medium-zoom-image {
  cursor: zoom-in;

  &--opened {
    cursor: zoom-out;
    z-index: 9999;
  }
}
.c-image-element {
  margin: 0;
  display: flex;
  flex-flow: column;
  &--full {
    float: none;
    margin: auto;
  }
  &__picture {
    margin: 0 auto;
    &--svg {
      width: 100%;
    }
  }
  &__img {
    max-width: 100%;
  }
  &__figcaption {
    font-family: CircularPro, sans-serif;
    margin-top: 0.75rem;
    font-size: 0.9rem;
    color: $color-grey;
  }
}
.c-article__section {
  &.c-image-element {
    @include bp(650px) {
      width: calc(60% - 2rem);
      padding: 0rem 0 1.5rem 1.5rem;
      float: right;
      &--full {
        width: 100%;
        float: none;
        margin: auto;
        padding: 0;
        .c-image-element__picture,
        .c-image-element__img {
          width: 100%;
        }
      }
    }
  }
}
</style>
